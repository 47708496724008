<template>
  <v-container>
    <v-card>
      <v-card-text>
        <entity-clients
          :entity-id="entity.id"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import EntityClients from '@/views/dashboard/pages/EntityClientsList/EntityClientsList'
export default {
  name: 'ClientsIndex',
  components: { EntityClients },
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
    entity () {
      return this.user.entity
    },
  },

}
</script>

<style scoped>

</style>
